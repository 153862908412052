<template>
  <div class="sdg" style="width: 100%; min-height: 100vh; background: rgb(237, 241, 255); padding-bottom: 50px"
    v-if="pay_way">
    <div style="width: 100%; background: #fff ;    padding-bottom: 10px;">
      <van-nav-bar style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd" title="新增usdt"
        left-arrow @click-left="$router.back()" />
      <div style="height: 46px"></div>
      <!-- 存款方式选择 -->


      <div v-if="pay_way == 'usdt'">
        <div class="tipsh">
          <div class="tops">USDT价格稳定 流通性高 不受监管 <span @click="$parent.goNav('/usdtmore')">了解更多 ></span></div>
          <div class="tsg">
            <div class="tsgs">绑定协议地址</div>
            <div class="tsgs">交易所划转</div>
            <div class="tsgs">完成取款</div>
          </div>
        </div>
        <div class="usrse">
          <div class="hgs">
            <div class="nams sc">
              钱包协议
              <div :class="meyXi == 'TRC20' ? ' ssa acti' : 'ssa'" @click="changXiyi('TRC20')">TRC20</div>
            </div>
            <div style="border-bottom: 1px solid #f2f2f2"></div>
          </div>
          <div style="height: 0.2rem; background: #f8f8f8; width: 100wh"></div>
          <div class="hgs">
            <div class="nams"><i style="color: red;">*</i>USDT地址</div>
            <div style="border-bottom: 1px solid #f2f2f2">
              <div data-v-a12ec382="" class="van-cell-group van-hairline--top-bottom">
                <div data-v-a12ec382="" class="van-cell van-field">
                  <div class="van-cell__value van-cell__value--alone van-field__value">
                    <div class="van-field__body"><input type="text" v-model="usdtadd" readonly
                        onfocus="this.removeAttribute('readonly');" auto-complete="off"
                        onblur="this.setAttribute('readonly',true);" placeholder="请输入USDT地址"
                        class="van-field__control" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="height: 0.2rem; background: #f8f8f8; width: 100wh"></div>
          <div class="hgs">
            <div class="nams"><i style="color: red;">*</i>支付密码</div>
            <div style="border-bottom: 1px solid #f2f2f2">
              <div data-v-a12ec382="" class="van-cell-group van-hairline--top-bottom">
                <div data-v-a12ec382="" class="van-cell van-field">
                  <div class="van-cell__value van-cell__value--alone van-field__value">
                    <div class="van-field__body"><input type="password" v-model="pwd" readonly
                        onfocus="this.removeAttribute('readonly');" auto-complete="off"
                        onblur="this.setAttribute('readonly',true);" placeholder="请输入支付密码" class="van-field__control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="height: 0.2rem; background: #f8f8f8; width: 100wh"></div>
        </div>
      </div>




      <div style="margin: 0 auto; width: 86%;  ">
        <van-button type="info" style="margin-top: 20px; width: 100%" @click="usdtBind">绑定</van-button>

      </div>
    </div>


  </div>
</template>
<script>
import Vue from 'vue';
import { Dialog, Toast } from 'vant';

export default {
  name: 'recharge',
  data() {
    return {
      usdtadd: '',
      pwd: '',
      pay_way: '',
      bankBox: {},
      payInfo: {},
      amount: null,
      cardLis: [],
      banklist: [],
      bankBox: {},
      meyXi: 'TRC20',
      payWayList: {},
      show: false,
      userbank: [],
      userUSD: [1],
      min_price: 100,
      max_price: 10000,
      mch_id: '',
      payimg: '',
    };
  },
  created() {
    let that = this;
    that.getPayWay();
    that.getBanklist();
    that.getcard();


  },
  methods: {
    usdtBind() {
      let that = this
      if (this.usdtadd == '') {
        Dialog.alert({
          message: 'USDT地址不能为空',
        }).then(() => {
          // on close
        });

        return false;
      }
      if (this.pwd == '') {
        Dialog.alert({
          message: '请输入支付密码',
        }).then(() => {
          // on close
        });

        return false;
      }
//bank:'USDT','bank_no':this.usdtadd,'bank_address':"TRC20",'pay_pass':this.pwd,'bank_owner':this.usdtadd
that.$apiFun.post('/api/bindcard', {bank:'USDT','bank_no':this.usdtadd,'bank_address':"TRC20",'pay_pass':this.pwd,'bank_owner':this.usdtadd}).then(res => {
   console.log(res )

   if(res.code =='200') {
    Toast('绑定成功')
    this.$parent.goNav('/withdrawal')
   } else if(res.code =='500') {
    Toast('通讯错误')
   } else {
    Toast(res.message)
   }
      });



    },
    getPayRange() {
      let that = this;
      let type = null;
      console.log('ddd', that.pay_way)
      if (that.pay_way == 'bank') {
        type = 'bank';
      }
      if (that.pay_way == 'wechat') {
        type = 'wechat';
      }
      if (that.pay_way == 'alipay') {
        type = 'alipay';
      }
      if (that.pay_way == 'usdt') {
        if (that.meyXi == 'ERC20') {
          type = 'usdt-erc20';
        }
        if (that.meyXi == 'TRC20') {
          type = 'usdt-trc20';
        }
      }

      if (that.pay_way == 'third') {
        type = 'third';
      }
      // bank/wechat/alipay/usdt-erc20/usdt-trc20/ebpay    min_price max_price
      that.showLoading();

      that.$apiFun
        .post('/api/getPayRange', { type })
        .then(res => {
          if (res.code == 200) {
            that.min_price = res.data.min_price;
            that.max_price = res.data.max_price;
            if (res.data.mch_id != null) {
              that.mch_id = res.data.mch_id
            }
            if (res.data.payimg != null) {
              that.payimg = this.$store.state.appInfo.pc_url + "/uploads/" + res.data.payimg

            }
          }
          that.hideLoading();
        })
        .catch(res => {
          that.hideLoading();
        });
    },
    changShow() {
      this.show = !this.show;
    },
    onConfirm(value, index) {
      this.bankBox.bank = value.bank_name;
      this.show = false;
    },
    onChange(picker, value, index) { },
    onCancel() {
      this.show = false;
    },
    changXiyi(val) {
      if (this.meyXi == val) {
        return;
      }
      this.meyXi = val;
      this.getPayRange();
    },
    getPayWay() {
      let that = this;
      that.showLoading();

      that.$apiFun
        .get('/api/get_pay_way', {})
        .then(res => {
          if (res.code == 200) {
            that.payWayList = res.data;
            that.payWayList.rengong = 1;
            let obj = that.payWayList;
            for (let i in obj) {
              if (obj[i] == 1) {
                that.pay_way = i == 'card' ? 'bank' : i;
                that.hideLoading();
                that.getPayRange();

                return;
              }
            }
          }
          that.hideLoading();
        })
        .catch(res => {
          that.hideLoading();
        });
    },
    payCS() {
      //客服充值 
      let that = this;
      let info = {};

      // if (info.amount < that.min_price || info.amount > that.max_price) {
      //   that.showTost(0, `请输入金额在${that.min_price}-${that.max_price}之间！`);
      //   return;
      // }
      window.open(this.$store.state.appInfo.kf_url)


    },
    payTest() {
      let that = this;
      let info = {};

      // bank 情况下  bank  bank_address  bank_no  bank_owner
      if (that.pay_way == 'bank') {
        info = {
          paytype: that.pay_way,
          amount: that.amount * 1,
          bank: that.bankBox.bank,
          bank_address: that.bankBox.bank_address,
          bank_no: that.bankBox.bank_no,
          bank_owner: that.bankBox.bank_owner,
        };
        console.log(info);
        // 银行卡信息内容的限制
        if (!info.bank_owner) {
          that.showTost(0, '请输入存款人姓名');
          return;
        }
        if (!info.bank) {
          that.showTost(0, '请输入银行类型');
          return;
        }

        if (!info.bank_no) {
          that.showTost(0, '请输入银行卡号');
          return;
        }
        if (!info.bank_address) {
          that.showTost(0, '请输入银行开户行地址');
          return;
        }
      } else {
        //暂时
        info = {
          paytype: that.pay_way,
          amount: that.amount * 1,
        };
      }
      if (that.pay_way == 'usdt') {
        info.catepay = that.meyXi;
      }
      // usdt 情况下  catepay 必填

      // 支付的金额判断
      if (info.amount < that.min_price || info.amount > that.max_price) {
        that.showTost(0, `请输入金额在${that.min_price}-${that.max_price}之间！`);
        return;
      }
      that.showLoading();
      info.paytype = info.paytype == 'wechat' ? 'wxpay' : info.paytype;

      that.$apiFun
        .post('/api/recharge', info)
        .then(res => {
          console.log(res);
          if (res.code != 200) {
            that.showTost(0, res.message);
          }
          if (res.code == 200) {
            that.amount = null;
            if (that.pay_way == 'bank') {
              that.showTost(1, '提交成功，等待后台审核');
              that.bankBox = {};
              that.amount = null;
              that.hideLoading();
              that.$router.push({ path: '/transRecord' });
              return;
            }
            that.bankBox = {};
            that.amount = null;
            that.$router.push({ path: `/payInfo?deposit_no=${res.message}` });
          }
          that.hideLoading();
        })
        .catch(res => {
          that.hideLoading();
        });
    },
    changMey(val) {
      this.amount = val * 1;
    },
    getBanklist() {
      let that = this;
      that.$apiFun
        .post('/api/banklist', {})
        .then(res => {
          if (res.code != 200) {
            that.showTost(0, res.message);
          }
          if (res.code == 200) {
            that.banklist = res.data;
          }
          that.hideLoading();
        })
        .catch(res => {
          that.hideLoading();
        });
    },
    getcard() {
      let that = this;
      that.showLoading();
      that.$apiFun
        .post('/api/getpaybank', {})
        .then(res => {
          if (res.code != 200) {
            that.showTost(0, res.message);
          }
          if (res.code == 200) {
            that.cardLis = res.data;

            that.hideLoading();
          }
        })
        .catch(res => {
          that.hideLoading();
        });
    },
    changPayway(val) {
      let that = this;
      if (val == that.pay_way) {
        return;
      }
      that.pay_way = val;
      that.bankBox = {};
      that.payInfo = {};
      that.amount = null;
      that.getPayRange();
    },
    goNav(url) {
      let that = this;
      that.$parent.goNav(url);
    },
    doCopy(msg) {
      let cInput = document.createElement('input');
      cInput.style.opacity = '0';
      cInput.value = msg;
      document.body.appendChild(cInput);
      // 选取文本框内容
      cInput.select();

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand('copy');

      // 复制成功后再将构造的标签 移除
      this.showTost(1, '复制成功！');
    },
    getUserInfo() {
      let that = this;
      that.$parent.getUserInfo();
    },
    showLoading() {
      this.$parent.showLoading();
    },
    hideLoading() {
      this.$parent.hideLoading();
    },
    openKefu() {
      this.$parent.openKefu();
    },
    showTost(type, title) {
      this.$parent.showTost(type, title);
    },
    getuseCardlist() {
      let that = this;
      that.$apiFun.post('/api/getcard', { type: 1 }).then(res => {
        if (res.code == 200) {
          that.userbank = res.data;
        }
      });
      that.$apiFun.post('/api/getcard', { type: 2 }).then(res => {
        if (res.code == 200) {
          that.userUSD = res.data;
        }
      });
    },
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log(vm);
      let that = this;
      vm.getuseCardlist();
    });
  },
};
</script>

<style lang="scss" scoped>
//@import '/style.css';
.textcns {
  font-size: medium;
}

.tipsh {
  width: 95%;
  margin: 6px auto;
  border-radius: 10px;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 6px;

  .tops {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.4rem;
    font-weight: 700;
    color: #333;
    height: 1rem;

    span {
      font-size: 0.29rem;
      font-weight: 400;
    }
  }

  .tsg {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tsgs {
      height: 0.56rem;
      line-height: 0.56rem;
      color: #a5a9b3;
      font-size: 0.2rem;
      text-align: center;
      padding: 4px 8px;
      flex: 1;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAA0CAYAAADPCHf8AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALCSURBVHgB7d0hcNtAEIXhtWh4y8vDixseXHPz4ibYKW2Kw1UsHt7g8vCWB7u7mYsnss7S3elmam3+b8bjxM6Evaz1tFJWu91uKyLnAixbt1qt7qSyRh+3Aizfpf6xv5TKGk3dX31uBVi+jYak6qehJjx3+ngSYPmuNCTvpZLngOgUsXBU//wG/Adn+thqSM6kgpcJYiG516ffAizfO31cSQXNwfc/BfDhXKfIRmbqBUSniE2QewF8sGZrLTM0kdfsWIQDdnix1pB8lEKDgIQD9k4AP75oSD5IgdgEsZDYeZE/AvhgjVZR/duMvPdDAD+s2fqaW/8eDUg4YKf2hSf2MSur2Wom3mdPC95c5DRbowFhTwtOWbN1kfKDUxPEsKcFjzYpzdZkQNjTglNJzVbKBGFPC15NNltJAQnY04JHo81WckDY04JjR5utnAli2NOCV+vYJbtZAWFPC84Nmq3cCWIsIOxpwavt62YrOyBhirCnBa96l+yWTBD2tODd/pLdlRTShNkv4QQiPOuKJgjwRjzNCcisa32BE/dgFw4WBSRsQiZtQwILZFvs3+2L0gnyWQCfLBzXoa3ND0g4JV/t1o7AibnRcOzP82UFJDRX1e+gDZyIOw3H4+sXcieITY8q9zwFTkyr4RisUSUHJEwPDszh0UO41dVAzgTZCuDPvrGKSQpIqHU5MIc3vcYqZjIg4aMVtS68sVD0GquYlAlirRXTA960h41VzGhAqHXhVLSxipmaIOxbwZvuWGMVczQg7FvBocfc/6U+NkE4MIcn1lh9k0zRgLBvBWessbqeaqxiBgEJB+afBPDjtiQcJjZBmB7wxBqrX1KoFxD2reBMVmMVczhB2LeCF9mNVcw+IOxbwZGixirmOSDsW8GR4sYq5mWCMD3gxU2tcJgmTA9WSuBBG+76WY1NEMIBD9q5jVXMPzyG0oj5jr9QAAAAAElFTkSuQmCC);
      background-size: cover;
    }
  }
}

.usrse {
  background: #fff;
  box-sizing: border-box;
  padding-top: 5px;

  .hgs {
    width: calc(100% - 40px);
    margin: 0 auto;
  }

  .nams {
    font-size: 0.38rem;
    color: #000;
    vertical-align: middle;
    margin-top: 10px;
    font-weight: 700;
  }

  .imgsa {
    position: relative;
    height: 2rem;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 0.2rem;

    .bisn {
      width: 0.8rem;
      position: absolute;
      bottom: 0.3rem;
      left: 1.4rem;
    }

    img {
      width: 2rem;
      border-radius: 50%;
    }
  }
}

[class*='van-hairline']:after {
  border: none;
}

.sc {
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  .ssa {
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    width: 2rem;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.4rem;
    font-weight: 700;
    text-align: center;
    margin-left: 1rem;
  }

  .acti {
    color: #597ef7 !important;
    border: 1px solid #597ef7 !important;
  }
}

.typelis {
  width: 95%;
  margin: 6px auto;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tyls {
    border-radius: 10px;
    border: 1px solid #eee;
    text-align: center;
    width: calc(25% - 0.2rem);
    padding: 0.2rem 0;
    font-size: 0.3rem;

    img {
      width: 0.7rem;
      display: block;
      margin: 0 auto;
      margin-bottom: 0.2rem;
    }
  }

  .tyls.atc {
    border: 1px solid #597ef7;
    color: #597ef7;
  }
}

.lasthg {
  display: flex;
  align-items: center;
  font-size: 0.33rem;
  color: #a5a9b3;
  padding: 0.2rem 0;
}

.bans {
  background-image: linear-gradient(180deg, #fff, #f9fcff);
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;

  p {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .frists {
      width: 60px;
    }

    .sdsw {
      flex: 1;
    }

    .copy {
      color: #069b71;
    }
  }
}
</style>