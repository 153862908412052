<template>
  <!-- <div>    
<div class="point" ref="point" :style="`width:${divWidth}px;height:${divHeight}px;left:${moveLeft}px;bottom:${moveBottom}px;`"
@click="gohome()"  
    >
    <img src="/static/gohome.png" alt="" width="70" height="70">
    </div>
 
    <div v-if="url" style="height: calc(100vh - 46px); overflow-y: scroll; -webkit-overflow-scrolling: touch">
      <iframe v-if="!type" style="height: 100%; width: 100%" ref="iframe" scrolling="auto" frameborder="0" id="iframe"></iframe>
      <iframe v-else :src="url" style="height: 100%; width: 100%" ref="iframe" scrolling="auto" frameborder="0" id="iframe"></iframe>
    </div>



  </div> -->
  <div>
    <van-nav-bar style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd" title="" left-arrow @click-left="gohome()" />
    <div style="height: 46px"></div>
    <div v-if="url" style="height: calc(100vh - 46px); overflow-y: scroll; -webkit-overflow-scrolling: touch">
      <iframe v-if="!type" style="height: 100%; width: 100%" ref="iframe" scrolling="auto" frameborder="0" id="iframe"></iframe>
      <iframe v-else :src="url" style="height: 100%; width: 100%" ref="iframe" scrolling="auto" frameborder="0" id="iframe"></iframe>
    </div>
  </div>

</template>
<script>
export default {
  name: 'gamePage',
  data() {
    return {
      url: null,
      type: null, 
      border_distance:10,//可拖拽元素距离屏幕边界最小距离
     divWidth:80,//可拖拽元素宽度
     divHeight:80,//可拖拽元素高度
     moveLeft:20,//可拖拽元素距离屏幕左边界
     moveTop:60,//可拖拽元素距离屏幕上边界
     moveRight:20, 
     moveBottom:80,
     clientWidth:0,//屏幕宽度
     clientHeight:0//屏幕高度 
    };
  },
  created() {
    let that = this;
    var query = that.$route.query;
    // 打开代理
    console.log(query);
    if (query.dailiD == 1) {
      that.getAgentLoginUrl();
      return;
    }
    // 打开客服
    if (query.dailiD == 2) {
      that.getservicerurl();
      return;
    }
    if (query.app == 1) {
      that.type = 'app';

      that.url = that.$store.state.appInfo.ios_download_url;
      return;
    }
    if (query.name) {
      that.goGamePage(query.name, query.type, query.code);
    }
  },
  methods: {
    gohome() {
       location.href= this.$store.state.appInfo.h5_url

    },
    // 打开客服
    getservicerurl() {
      let that = this;
      that.$apiFun.post('/api/getservicerurl', {}).then(res => {
        if (res.code != 200) {
          that.showTost(0, res.message);
        }
        if (res.code == 200) {
          that.url = res.data.url;
        }
      });
    },
    // 打开代理
    getAgentLoginUrl() {
      let that = this;
      that.$apiFun.get('/api/getAgentLoginUrl', {}).then(res => {
        if (res.code != 200) {
          that.showTost(0, res.message);
        }
        if (res.code == 200) {
          that.url = res.data.url;
        }
      });
    },
    // 打开游戏 gamePage?name=HG&type=4&code=0
    goGamePage(name, type, code) {
      let that = this;
// console.log(name,type)
      that.$apiFun
        .post('/api/getGameUrl', { plat_name: name, game_type: type || 0, game_code: code, is_mobile_url: 1 })
        .then(res => {
          console.log(res);
          if (res.code != 200) {
            that.$parent.showTost(0, res.message);
          }
          if (res.code == 200) {
            if(name !='HG') {
              if(name !='AG' & type!='2') {

                that.url = res.data.url;
              } else {
                location.href = res.data.url;
              }
            } else {
               
              location.href = res.data.url;
            }
          }   
        })
        .catch(res => {});
    },  
    dragStart(e){
     this.$refs.point.style.transition="none";
   },
  dragEnd(e){
     this.$refs.point.style.transition = 'all 0.3s';
    //  if(this.moveLeft > this.clientWidth/2){//若移动位于屏幕的左边或右边，则自动贴合左边或右边（此根据业务具体需求表现而定）
    //     this.moveLeft = this.clientWidth - this.divWidth - this.border_distance;
    //  }else{
    //     this.moveLeft = this.border_distance;
    //  }  
    this.moveRight = this.border_distance;     
  },
  dragMove(e){
     if(e.touches.length){
        let touch = e.touches[0];
        this.moveLeft = touch.clientX - this.divWidth/2;//因拖动的中间点为Dom节点的中心点，所以left需减去Dom节点一半的宽度
        this.moveTop = touch.clientY - this.divHeight/2;//top原理同上
     }
  }
 
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
    that.$refs.iframe.contentWindow.location.replace(that.url);
  },
};
</script>

<style lang="scss" scoped>
.out {//最外层div
  height: 100%;
  position: relative;
}
.point{

width:50px;
height:50px;
  border-radius: 50%;
  position: absolute;
}
 

</style>

 