<template>
  <div>    
    <van-nav-bar
    title="电子场馆"
    left-text=""
     left-arrow
    @click-left="onClickLeft"
   />

  
<div>

  <div class="searcher" style="height:250px ;  background: url(/static/ag_img/ag.png);background-repeat: no-repeat;background-position-x: 50%;
 " > </div>

</div>




<div class="gameBoxs">
 <div style="width: 100%;">

<van-tabs v-model="active"  @click="tabHandler">
    <van-tab title="CQ9电子" ></van-tab>  
    <van-tab title="PG电子"  ></van-tab>  
    <van-tab title="AG电子"   ></van-tab>
    <van-tab title="PP电子"  ></van-tab>    

    <van-tab title="FC电子" ></van-tab>    
  </van-tabs>
</div> 
<div style="width: 100%;" class="text"> 


  <van-grid :border="false" :column-num="3" style="padding-bottom: 4px;"  v-if="currentPage==1">

    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '184')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30016/default.avif"   class="img"  />
      <div>  六颗扭蛋  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', 'BU01')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30018/default.avif"   class="img"  />
      <div>  水浒传  </div>
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '52')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30022/default.avif"   class="img"  />
      <div>  跳高高  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '52')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30023/default.avif"   class="img"  />
      <div>  跳起来  </div>
    </van-grid-item>


    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '99')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30024/default.avif"   class="img"  />
      <div>  跳更高  </div>
    </van-grid-item>


    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '10')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30026/default.avif"   class="img"  />
      <div>  五福临门  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '111')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30027/default.avif"   class="img"  />
      <div>  飞起来  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '50')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30028/default.avif"   class="img"  />
      <div>  鸿福齐天  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '31')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30029/default.avif"   class="img"  />
      <div>  武圣  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '64')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30030/default.avif"   class="img"  />
      <div>  宙斯  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '205')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30031/default.avif"   class="img"  />
      <div>  蹦迪  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '138')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30032/default.avif"   class="img"  />
      <div>  跳过来  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '137')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30033/default.avif"   class="img"  />
      <div>  直式蹦迪  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '105')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30034/default.avif"   class="img"  />
      <div>  单手跳高高  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '69')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30035/default.avif"   class="img"  />
      <div>  发财神  </div>
    </van-grid-item>
  </van-grid>



<van-grid :border="false" :column-num="3" style="padding-bottom: 4px;"  v-if="currentPage==2">

    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '89')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30036/default.avif"   class="img"  />
      <div>  雷神  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '183')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30038/default.avif"   class="img"  />
      <div>  野狼Disco  </div>
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '83')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30039/default.avif"   class="img"  />
      <div>  火之女王  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '153')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30040/default.avif"   class="img"  />
      <div>  六颗糖  </div>
    </van-grid-item>


    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '9')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30042/default.avif"   class="img"  />
      <div>  钟馗运财  </div>
    </van-grid-item>


    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '15')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30044/default.avif"   class="img"  />
      <div>  金鸡报喜  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '117')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30045/default.avif"   class="img"  />
      <div>  东方神起  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '140')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30046/default.avif"   class="img"  />
      <div>  火烧连环船2  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '115')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30047/default.avif"   class="img"  />
      <div>  冰雪女王  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '127')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30048/default.avif"   class="img"  />
      <div>  直式武圣  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '29')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30049/default.avif"   class="img"  />
      <div>  水世界  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '79')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30050/default.avif"   class="img"  />
      <div>  变色龙  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '8')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30051/default.avif"   class="img"  />
      <div>  甜蜜蜜  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '147')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30052/default.avif"   class="img"  />
      <div>  花开富贵  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '203')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30053/default.avif"   class="img"  />
      <div>  嗨起来  </div>
    </van-grid-item>
  </van-grid>


  <van-grid :border="false" :column-num="3" style="padding-bottom: 4px;"  v-if="currentPage==3">

    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '113')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30054/default.avif"   class="img"  />
      <div>  飞天财神  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '58')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30055/default.avif"   class="img"  />
      <div>  金鸡报囍2  </div>
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '105')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30056/default.avif"   class="img"  />
      <div>  单手跳起來  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '173')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30057/default.avif"   class="img"  />
      <div>  666  </div>
    </van-grid-item>


    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '128')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30058/default.avif"   class="img"  />
      <div>  转大钱  </div>
    </van-grid-item>


    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '154')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30059/default.avif"   class="img"  />
      <div>  宙斯他爹  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '116')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30060/default.avif"   class="img"  />
      <div>  梦游仙境  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '152')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30061/default.avif"   class="img"  />
      <div>  双飞  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '108')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30062/default.avif"   class="img"  />
      <div>  直式跳更高  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '1074')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30063/default.avif"   class="img"  />
      <div>  聚宝盆  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '5')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30064/default.avif"   class="img"  />
      <div>  金大款  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '67')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30067/default.avif"   class="img"  />
      <div>  赚金蛋  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '161')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30068/default.avif"   class="img"  />
      <div>  大力神  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '131')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30069/default.avif"   class="img"  />
      <div>  直式发财神  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '16')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30070/default.avif"   class="img"  />
      <div>  五行  </div>
    </van-grid-item>
  </van-grid>

  
  <van-grid :border="false" :column-num="3" style="padding-bottom: 4px;"  v-if="currentPage==4">

    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '72')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30071/default.avif"   class="img"  />
      <div>  好运年年  </div>
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '1')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30072/default.avif"   class="img"  />
      <div>  钻石水果王  </div>
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '201')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30073/default.avif"   class="img"  />
      <div>  拳霸  </div>
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '142')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30074/default.avif"   class="img"  />
      <div>  火神  </div>
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '143')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30075/default.avif"   class="img"  />
      <div>  发财福娃  </div>
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '163')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30076/default.avif"   class="img"  />
      <div>  哪吒再临  </div>
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '33')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30077/default.avif"   class="img"  />
      <div>  火烧连环船  </div>
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '150')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30078/default.avif"   class="img"  />
      <div>  寿星大发  </div>
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '1010')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30079/default.avif"   class="img"  />
      <div>  五福临门JP  </div>
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('CQ9', '2', '123')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/3/3/30080/default.avif"   class="img"  />
      <div>  直式五福临门  </div>
    </van-grid-item> 

  </van-grid>


  <div style="margin-top: 10px;margin-bottom:30px"> 
    <van-pagination v-model="currentPage" :total-items="21" :items-per-page="2"    />
  </div>
    


  

</div>
</div>

  </div>
</template>
<script>
export default {
  name: 'gamePagePG',
  data() {
    return { 
      currentPage:1,
      active:0,
    };
  },
  created() {
    let that = this;
    var query = that.$route.query;
    console.log(query);
 
  },
  methods: { 
    tabHandler(name, title) {
      console.log(name, title)
      if(title =='PG电子') {
        this.$parent.openGamePage('PG', '2', '')
      }
      if(title =='AG电子') {
        this.$parent.openGamePage('AG', '2', '')
      }
      if(title =='FC电子') {
        this.$parent.openGamePage('FC', '2', '')
      }
      if(title =='PP电子') {
        this.$parent.openGamePage('PP', '2', '')
      }
    },
    onClickLeft()
    {
      let that = this;
      that.$router.go(-1)
    }
 
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
    //that.$refs.iframe.contentWindow.location.replace(that.url);
  },
};
</script>

<style lang="scss" scoped>
 
// 游戏
.gameBoxs {
  background: #edf1ff;
  box-sizing: border-box;
  padding: 10px;
  color: #5d75a2; 
 
 
 
}
::v-deep  .van-grid-item__content {
  padding: 8px 8px;
}
::v-deep .van-grid-item__content{
  background: #edf1ff !important; 
}
.text div{
  font-size: 14px;
  color: #5d4747;
  padding-top: 4px;
  

}
.img{
   width: 100%; height: 100%; display: block;
    border-radius: 10px; 
   //border: 1px solid rgb(19, 19, 19);  

}
</style>

 