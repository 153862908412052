<template>
  <div>    
    <van-nav-bar
    title="AG电子"
    left-text=""
     left-arrow
    @click-left="onClickLeft"
   />

  
<div>

  <div class="searcher" style="height:250px ;  background: url(/static/ag_img/ag.png);background-repeat: no-repeat;background-position-x: 50%;
 " > </div>

</div>




<div class="gameBoxs">
 <div style="width: 100%;">

<van-tabs v-model="active" @click="tabHandler"> 
    <van-tab title="AG电子" ></van-tab> 
    <van-tab title="PG电子"   ></van-tab>
    <van-tab title="CQ9电子"  ></van-tab>    
    <van-tab title="PP电子" ></van-tab> 

    <van-tab title="FC电子" ></van-tab>    
  </van-tabs>
</div> 
<div style="width: 100%;" class="text"> 


  <van-grid :border="false" :column-num="3">
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'WH10')"    >
      <van-image src="/static/ag_img/WH10.png"   class="img"  />
      
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'SB33')"    >
      <van-image src="/static/ag_img/SB33.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'SB07')"    >
      <van-image src="/static/ag_img/SB07.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'XG05')"    >
      <van-image src="/static/ag_img/XG05.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'WH38')"    >
      <van-image src="/static/ag_img/WH38.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'WH22')"    >
      <van-image src="/static/ag_img/WH22.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'WA01')"    >
      <van-image src="/static/ag_img/WA01.png"   class="img"  />
      
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'XG10')"    >
      <van-image src="/static/ag_img/XG10.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'EP02')"    >
      <van-image src="/static/ag_img/EP02.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'XG13')"    >
      <van-image src="/static/ag_img/XG13.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'FRU2')"    >
      <van-image src="/static/ag_img/FRU2.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'FRU')"    >
      <van-image src="/static/ag_img/FRU.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'XG07')"    >
      <van-image src="/static/ag_img/XG07.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'SB02')"    >
      <van-image src="/static/ag_img/SB02.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'SB28')"    >
      <van-image src="/static/ag_img/SB28.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'SC03')"    >
      <van-image src="/static/ag_img/SC03.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'XG04')"    >
      <van-image src="/static/ag_img/XG04.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'SB10')"    >
      <van-image src="/static/ag_img/SB10.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'WC01')"    >
      <van-image src="/static/ag_img/WC01.png"   class="img"  />
      
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'XG09')"    >
      <van-image src="/static/ag_img/XG09.png"   class="img"  />
      
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('AG', '2', 'WH20')"    >
      <van-image src="/static/ag_img/WH20.png"   class="img"  />
      
    </van-grid-item>
    








  </van-grid>
  <div style="margin-top: 10px;margin-bottom:30px"> 
    <van-pagination v-model="currentPage" :total-items="21" :items-per-page="2"    />
  </div>
    


  

</div>
</div>

  </div>
</template>
<script>
export default {
  name: 'gamePagePG',
  data() {
    return { 
      currentPage:1,
      active:0
    };
  },
  created() {
    let that = this;
    var query = that.$route.query;
    console.log(query);
 
  },
  methods: { 
    tabHandler(name, title) {
      console.log(name, title)
      if(title =='PP电子') {
        this.$parent.openGamePage('PP', '2', '')
      }
      if(title =='PG电子') {
        this.$parent.openGamePage('PG', '2', '')
      }
      if(title =='FC电子') {
        this.$parent.openGamePage('FC', '2', '')
      }
      if(title =='CQ9电子') {
        this.$parent.openGamePage('CQ9', '2', '')
      }
    },
    onClickLeft()
    {
      let that = this;
      that.$router.go(-1)
    }
 
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
   // that.$refs.iframe.contentWindow.location.replace(that.url);
  },
};
</script>

<style lang="scss" scoped>
 
// 游戏
.gameBoxs {
  background: #edf1ff;
  box-sizing: border-box;
  padding: 10px;
  color: #5d75a2; 
 
 
 
}
::v-deep  .van-grid-item__content {
  padding: 0px 8px;
}
::v-deep .van-grid-item__content{
  background: #edf1ff !important;
}
.text div{
  font-size: 14px;
  color: #5d4747;
  padding-top: 4px;

}
.img{
   width: 100%; height: 100%; display: block; border-radius: 4px; 
   //border: 1px solid rgb(19, 19, 19);  
}
</style>

 