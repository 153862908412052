<template>
  <div>    
    <van-nav-bar
    title="PG电子"
    left-text=""
     left-arrow
    @click-left="onClickLeft"
   />

  
<div>

  <div class="searcher" ><img  style="height:250px" src="/static/pg_img/pg.png"> </div>

</div>




<div class="gameBoxs">
 <div style="width: 100%;">

<van-tabs v-model="active" @click="tabHandler"> 
    <van-tab title="PG游戏" ></van-tab>   
     <van-tab title="AG电子"   ></van-tab>
    <van-tab title="CQ9电子"  ></van-tab>    
    <van-tab title="PP电子" ></van-tab> 

    <van-tab title="FC电子" ></van-tab> 
  </van-tabs>
</div> 
<div style="width: 100%;" class="text"> 


  <van-grid :border="false" :column-num="3"  v-if="currentPage==1">

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '37')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000037/default.avif"   class="img"  />
      <div>  圣诞欢乐送  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '59')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000059/default.avif"   class="img"  />
      <div>  忍者vs武侍  </div>
    </van-grid-item>



    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '26')"    >
      <img src="https://oss.oss9085cc5.top/game_pictures/g/CL/200/3/2000026/default.avif"   class="img"  />
      <div>  摇钱树  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '39')"    >
      <van-image src="/static/pg_img/39.png"   class="img"  />
      <div>  金猪报财  </div>
    </van-grid-item>
      
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '118')"    >
      <van-image src="/static/pg_img/118.png"   class="img"  />
      <div>  假面嘉年华  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '1402846')"    >
      <van-image src="/static/pg_img/1402846.png"   class="img"  />
      <div>  点石成金  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '123')"    >
      <van-image src="/static/pg_img/123.png"   class="img"  />
      <div>  斗鸡  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '40')"    >
      <van-image src="/static/pg_img/40.png"   class="img"  />
      <div>  水果丛林  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '42')"    >
      <van-image src="/static/pg_img/42.png"   class="img"  />
      <div>  象财神  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '63')"    >
      <van-image src="/static/pg_img/63.png"   class="img"  />
      <div>  龙虎争霸  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '68')"    >
      <van-image src="/static/pg_img/68.png"   class="img"  />
      <div>  鼠鼠福福  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '126')"    >
      <van-image src="/static/pg_img/68.png"   class="img"  />
      <div>  虎虎生财  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '1682240')"    >
      <van-image src="/static/pg_img/1682240.png"   class="img"  />
      <div>  快钱来了  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '65')"    >
      <van-image src="/static/pg_img/65.png"   class="img"  />
      <div>  麻将胡了  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '74')"    >
      <van-image src="/static/pg_img/74.png"   class="img"  />
      <div>  麻将胡了2  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '87')"    >
      <van-image src="/static/pg_img/87.png"   class="img"  />
      <div>  寻宝黄金城  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '60')"    >
      <van-image src="/static/pg_img/60.png"   class="img"  />
      <div>  爱尔兰精灵  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '89')"    >
      <van-image src="/static/pg_img/89.png"   class="img"  />
      <div>  招财喵  </div>
    </van-grid-item>
     </van-grid>
    <van-grid :border="false" :column-num="3"  v-if="currentPage==2">

    
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '104')"    >
      <van-image src="/static/pg_img/104.png"   class="img"  />
      <div>  亡灵大盗  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '106')"    >
      <van-image src="/static/pg_img/106.png"   class="img"  />
      <div>  麒麟送宝  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '57')"    >
      <van-image src="/static/pg_img/57.png"   class="img"  />
      <div>  寻龙探宝  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '48')"    >
      <van-image src="/static/pg_img/48.png"   class="img"  />
      <div>  双囍临门  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '53')"    >
      <van-image src="/static/pg_img/53.png"   class="img"  />
      <div>  冰雪大冲关  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '75')"    >
      <van-image src="/static/pg_img/75.png"   class="img"  />
      <div>  福运象财神  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '79')"    >
      <van-image src="/static/pg_img/79.png"   class="img"  />
      <div>  澳门壕梦  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '98')"    >
      <van-image src="/static/pg_img/98.png"   class="img"  />
      <div>  十倍金牛  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '135')"    >
      <van-image src="/static/pg_img/135.png"   class="img"  />
      <div>  赏金大对决  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '110')"    >
      <van-image src="/static/pg_img/110.png"   class="img"  />
      <div>  恐龙帝国  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '117')"    >
      <van-image src="/static/pg_img/117.png"   class="img"  />
      <div>  夜醉佳人  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '127')"    >
      <van-image src="/static/pg_img/127.png"   class="img"  />
      <div>  极速赢家  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '128')"    >
      <van-image src="/static/pg_img/128.png"   class="img"  />
      <div>  珀尔修斯传奇  </div>
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '67')"    >
      <van-image src="/static/pg_img/67.png"   class="img"  />
      <div>  少林足球  </div>
    </van-grid-item>


    
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '85')"    >
      <van-image src="/static/pg_img/85.png"   class="img"  />
      <div>  阿拉丁神灯  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '80')"    >
      <van-image src="/static/pg_img/80.png"   class="img"  />
      <div>  欢乐嘉年华  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '1543462')"    >
      <van-image src="/static/pg_img/1543462.png"   class="img"  />
      <div>  金钱兔  </div>
    </van-grid-item>
    

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '1580541')"    >
      <van-image src="/static/pg_img/1580541.png"   class="img"  />
      <div>  黑帮风云  </div>
    </van-grid-item>
  </van-grid>

  
    <van-grid :border="false" :column-num="3"  v-if="currentPage==3">

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '1451122')"    >
      <van-image src="/static/pg_img/1451122.png"   class="img"  />
      <div>  寻龙探宝 2  </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '1695365')"    >
      <van-image src="/static/pg_img/1695365.png"   class="img"  />
      <div>  金龙送宝 </div>
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '1671262')"    >
      <van-image src="/static/pg_img/1695365.png"   class="img"  />
      <div>  宝石矿工 </div>
    </van-grid-item>


    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '34')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000034/default.avif"   class="img"  />
      <div>  后羿射日 </div>
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '35')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000035/default.avif"   class="img"  />
      <div>  万胜狂欢夜 </div>
    </van-grid-item>
 
    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '36')"    >
      <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000036/default.avif"   class="img"  />
      <div>  舞狮进宝 </div>
    </van-grid-item>

<!-- --  -->
<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '62')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000062/default.avif"   class="img"  />
  <div>  宝藏征途 </div>
</van-grid-item>

<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '61')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000061/default.avif"   class="img"  />
  <div>  唐伯虎点秋香 </div>
</van-grid-item>


<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '70')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000070/default.avif"   class="img"  />
  <div>  糖果连连爆 </div>
</van-grid-item>

<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '64')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000064/default.avif"   class="img"  />
  <div>  拳霸 </div>
</van-grid-item>

<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '69')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000069/default.avif"   class="img"  />
  <div>  比基尼天堂 </div>
</van-grid-item>

<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '83')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000083/default.avif"   class="img"  />
  <div>  火树赢花 </div>
</van-grid-item>
<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '82')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000082/default.avif"   class="img"  />
  <div>  凤凰传奇 </div>
</van-grid-item>

<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '86')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000086/default.avif"   class="img"  />
  <div>  星旅淘金 </div>
</van-grid-item>

<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '88')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000088/default.avif"   class="img"  />
  <div>  金玉满堂 </div>
</van-grid-item>


<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '91')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000091/default.avif"   class="img"  />
  <div>  冰火双娇 </div>
</van-grid-item>


<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '92')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000092/default.avif"   class="img"  />
  <div>  水上泰神奇 </div>
</van-grid-item>

<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '93')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000093/default.avif"   class="img"  />
  <div>  新国粹 </div>
</van-grid-item>

</van-grid>
<van-grid :border="false" :column-num="3"  v-if="currentPage==4">
<!-- --  -->

<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '94')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000094/default.avif"   class="img"  />
  <div>  巴厘之旅 </div>
</van-grid-item>


<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '97')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000097/default.avif"   class="img"  />
  <div>  冰锋奇侠 </div>
</van-grid-item>

<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '100')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000100/default.avif"   class="img"  />
  <div>  糖心风暴 </div>
</van-grid-item>
<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '101')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000101/default.avif"   class="img"  />
  <div>  太阳神传说 </div>
</van-grid-item>
<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '102')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000102/default.avif"   class="img"  />
  <div>  人鱼公主 </div>
</van-grid-item>
<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '105')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000105/default.avif"   class="img"  />
  <div>  霹雳神偷 </div>
</van-grid-item>

<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '107')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000107/default.avif"   class="img"  />
  <div>  美猴王传奇 </div>
</van-grid-item>
<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '108')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000108/default.avif"   class="img"  />
  <div>  美洲野牛 </div>
</van-grid-item>
<van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '112')"    >
  <img src="https://oss.vip9085aa6.top/game_pictures/g/CL/200/3/2000112/default.avif"   class="img"  />
  <div>  江山美景图 </div>
</van-grid-item>


    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '54')"    >
      <van-image src="/static/pg_img/54.png"   class="img"  />
      <div>  赏金船长  </div>
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('PG电子', 'PGWAP', '84')"    >
      <van-image src="/static/pg_img/84.png"   class="img"  />
      <div>  赏金女王  </div>
    </van-grid-item>



    
  </van-grid>


  <div style="margin-top: 10px;margin-bottom:30px"> 
    <van-pagination v-model="currentPage" :total-items="42" :items-per-page="2"  :page-count="4"   />
  </div>
    


</div>
</div>

  </div>
</template>
<script>
export default {
  name: 'gamePagePG',
  data() {
    return { 
      active:0,
      currentPage:1,
    };
  },
  created() {
    let that = this;
    var query = that.$route.query;
    console.log(query);
 
  },
  methods: {
    tabHandler(name, title) {
      console.log(name, title)
      if(title =='PP电子') {
        this.$parent.openGamePage('PP', '2', '')
      }
      if(title =='AG电子') {
        this.$parent.openGamePage('AG', '2', '')
      }
      if(title =='FC电子') {
        this.$parent.openGamePage('FC', '2', '')
      }
      if(title =='CQ9电子') {
        this.$parent.openGamePage('CQ9', '2', '')
      }
    },
    onClickLeft()
    {
      let that = this;
      that.$router.go(-1)
    }
 
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
    that.$refs.iframe.contentWindow.location.replace(that.url);
  },
};
</script>

<style lang="scss" scoped>
 
// 游戏
.gameBoxs {
  background: #edf1ff;
  box-sizing: border-box;
  padding: 10px;
  color: #5d75a2; 
 
 
 
}
::v-deep  .van-grid-item__content {
  padding: 8px 8px;
}
::v-deep .van-grid-item__content{
  background: #edf1ff !important;
}
.text div{
  font-size: 14px;
  color: #5d4747;
  padding-top: 4px;

}
.img{
   width: 100%; height: 100%; display: block; border-radius: 14px; 
   //border: 1px solid rgb(19, 19, 19);  
}
</style>

 