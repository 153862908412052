<template>


  <div style="background-color:#ecf0ff;">

    <van-nav-bar style=" background-color: #edf2fd" title="客服中心" left-arrow @click-left="$router.back()" />



    <div class="contact">
      <div class="header">
        <div class="center" @click="goKf"> <van-icon name="service" /> 在线客服 </div>
      </div>
      <div class="summary">
        <div class="logo">
          <img src="/static/tel-bg.png">
        </div>
        <div class="telphone">
          <div class="kefu" style="height: 600px;">
            <div data-v-3dd3ae4d="" class="style__list_wrap__3w1Z6">
              <div data-v-3dd3ae4d="" class="style__item__1qkvM"><img data-v-3dd3ae4d=""
                  src="/static/image/1587555761884253.png" class="style__item_icon__3_KVR">
                <div data-v-3dd3ae4d="" class="style__right_area__1kqQE"><span data-v-3dd3ae4d=""
                    class="style__item_name__W3EYL">关于我们</span>
                  <div data-v-3dd3ae4d="" class="style__item_short_title__3zQpB"><i data-v-3dd3ae4d=""
                      class="style__item_arrow__2jXw7"></i></div>
                </div>
              </div>
              <div data-v-3dd3ae4d="" class="style__item__1qkvM"><img data-v-3dd3ae4d=""
                  src="/static/image/1587555761884253.png" class="style__item_icon__3_KVR">
                <div data-v-3dd3ae4d="" class="style__right_area__1kqQE"><span data-v-3dd3ae4d=""
                    class="style__item_name__W3EYL">常见问题</span>
                  <div data-v-3dd3ae4d="" class="style__item_short_title__3zQpB"><i data-v-3dd3ae4d=""
                      class="style__item_arrow__2jXw7"></i></div>
                </div>
              </div>
              <div data-v-3dd3ae4d="" class="style__item__1qkvM"><img data-v-3dd3ae4d=""
                  src="/static/image/1587555761884253.png" class="style__item_icon__3_KVR">
                <div data-v-3dd3ae4d="" class="style__right_area__1kqQE"><span data-v-3dd3ae4d=""
                    class="style__item_name__W3EYL">隐私政策</span>
                  <div data-v-3dd3ae4d="" class="style__item_short_title__3zQpB"><i data-v-3dd3ae4d=""
                      class="style__item_arrow__2jXw7"></i></div>
                </div>
              </div>
              <div data-v-3dd3ae4d="" class="style__item__1qkvM"><img data-v-3dd3ae4d=""
                  src="/static/image/1587555761884253.png" class="style__item_icon__3_KVR">
                <div data-v-3dd3ae4d="" class="style__right_area__1kqQE"><span data-v-3dd3ae4d=""
                    class="style__item_name__W3EYL">免责说明</span>
                  <div data-v-3dd3ae4d="" class="style__item_short_title__3zQpB"><i data-v-3dd3ae4d=""
                      class="style__item_arrow__2jXw7"></i></div>
                </div>
              </div>
              <div data-v-3dd3ae4d="" class="style__item__1qkvM"><img data-v-3dd3ae4d=""
                  src="/static/image/1587555761884253.png" class="style__item_icon__3_KVR">
                <div data-v-3dd3ae4d="" class="style__right_area__1kqQE"><span data-v-3dd3ae4d=""
                    class="style__item_name__W3EYL">联系我们</span>
                  <div data-v-3dd3ae4d="" class="style__item_short_title__3zQpB"><i data-v-3dd3ae4d=""
                      class="style__item_arrow__2jXw7"></i></div>
                </div>
              </div>
              <div data-v-3dd3ae4d="" class="style__item__1qkvM"><img data-v-3dd3ae4d=""
                  src="/static/image/1587555761884253.png" class="style__item_icon__3_KVR">
                <div data-v-3dd3ae4d="" class="style__right_area__1kqQE"><span data-v-3dd3ae4d=""
                    class="style__item_name__W3EYL">代理加盟</span>
                  <div data-v-3dd3ae4d="" class="style__item_short_title__3zQpB"><i data-v-3dd3ae4d=""
                      class="style__item_arrow__2jXw7"></i></div>
                </div>
              </div>
              <div data-v-3dd3ae4d="" class="style__item__1qkvM"><img data-v-3dd3ae4d=""
                  src="/static/image/1587555761884253.png" class="style__item_icon__3_KVR">
                <div data-v-3dd3ae4d="" class="style__right_area__1kqQE"><span data-v-3dd3ae4d=""
                    class="style__item_name__W3EYL">博彩责任</span>
                  <div data-v-3dd3ae4d="" class="style__item_short_title__3zQpB"><i data-v-3dd3ae4d=""
                      class="style__item_arrow__2jXw7"></i></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'kefu',
  data() {
    return {

    };
  },
  created() {
    let that = this;

  },
  methods: {
    onClickLeft() {

    },
    goKf() {
      let that = this;
      let info = {};


      window.open(this.$store.state.appInfo.kf_url)
    }
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
  },
  beforeDestroy() {

  },
};
</script>
<style lang="scss" scoped>


.style__list_wrap__3w1Z6 {
  border-radius: .16rem;
  overflow: hidden;
  -webkit-box-shadow: 0 0 .12rem rgba(19, 88, 152, .1);
  box-shadow: 0 0 .12rem rgba(19, 88, 152, .1);
  padding: 0 .22rem 0 .24rem;
  background: #fff;
}

.style__list_wrap__3w1Z6 .style__item__1qkvM {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: .84rem;
  padding: .32rem .02rem .22rem .04rem;
  border-bottom: .01rem solid #eee;
  -webkit-box-sizing: content-box;
  box-sizing: initial;
}

.style__list_wrap__3w1Z6 .style__item__1qkvM .style__right_area__1kqQE {
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.style__list_wrap__3w1Z6 .style__item__1qkvM .style__item_name__W3EYL {
  font-size: .28rem;
  color: #3b424b;
}

.style__list_wrap__3w1Z6 .style__item__1qkvM .style__item_short_title__3zQpB {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: .24rem;
  color: #caced9;
}

.style__list_wrap__3w1Z6 .style__item__1qkvM .style__item_arrow__2jXw7 {
  display: inline-block;
  width: .32rem;
  height: .32rem;
  margin-left: .1rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAARVBMVEUAAAD////R1+DS2ePS2ODS2OHS1+HS2OHS2OLS2OLT2eLT2OLT2eL////R2OHR1+DS1+HS1+DS1+HS2OHS2ePT2+LR1+ALbATjAAAAFnRSTlMAAvpPpqnzoZZ5b2VZBPbImo6HVUwjNtqTwgAAAFdJREFUOMvlzckRgDAMQ1E7ZAXCjvovlTSAzJEBXd+fkfxwZe6o6wTHiwyjUP+SIjwqTlbsAA7iowNCJQ7A670PzZPhC/nvm2fDV+IagU3YUizCV+WjuwAQ4ASq8pLTZwAAAABJRU5ErkJggg==) 0 0 no-repeat;
  background-size: cover;
}

.style__list_wrap__3w1Z6 .style__item__1qkvM .style__item_icon__3_KVR {
  width: .64rem;
  height: .64rem;
  margin-right: .2rem;
  margin-top: 3px;
}



.contact {
  width: 100%;
}

.header {
  height: 1.88rem;
  line-height: 1.88rem;
  padding: 0 0.4rem;
  color: #fff;
  font-size: 0.28rem;
  background: #4974f5;
}

.header .center {
  width: 100%;
  float: left;
  font-size: 0.65rem;
  text-align: center;
}

.summary {}

.logo {
  text-align: center;
}

.logo img {
  width: 100%;
}

.telphone {
  margin: 1.5rem 0;
}

.kefu {
  margin: 0 auto;
  height: 3rem;
  width: 86%;
}

.kefu img {
  float: left;
  width: 1.8rem;
  margin-top: 0.5rem;
}

.kefu p {
  line-height: 2.8rem;
  margin-left: 2.3rem;
  color: #555;
}
</style>