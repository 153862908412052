<template>
  <div>    
    <van-nav-bar
    title="PP电子"
    left-text=""
     left-arrow
    @click-left="onClickLeft"
   />

  
<div>

  <div class="searcher" style="    background-image: url(https://www.asgam.com/wp-content/uploads/2021/11/1553789885PRAGMATIC-PLAY.png);
    height: 197px;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: 0;
    background-size: contain;" >
    <!-- <img  style="height:250px" src="/static/pg_img/pg.png"> -->
  
  
  </div>

</div>




<div class="gameBoxs">
 <div style="width: 100%;">

<van-tabs v-model="active"  @click="tabHandler">
    <van-tab title="PP电子" ></van-tab> 
    <van-tab title="PG电子"  ></van-tab>  
    <van-tab title="AG电子"   ></van-tab>
    <van-tab title="CQ9电子"  ></van-tab>    

    <van-tab title="FC电子" ></van-tab>   
  </van-tabs>
</div> 
<div style="width: 100%;" class="text"> 


  <van-grid :border="false" :column-num="3" v-if="currentPage==1">
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs40rainbowr')"    >
      <van-image src="/static/pp_img/vs40rainbowr.png"   class="img"  />
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10bhallbnza')"    >
      <van-image src="/static/pp_img/vs10bhallbnza.png"   class="img"  />
    </van-grid-item>


    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20bnnzdice')"    >
      <van-image src="/static/pp_img/vs20bnnzdice.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs5gemstone')"    >
      <van-image src="/static/pp_img/vs5gemstone.png"   class="img"  />
    </van-grid-item>

    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysraghex')"    >
      <van-image src="/static/pp_img/vswaysraghex.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20maskgame')"    >
      <van-image src="/static/pp_img/vs20maskgame.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20gravity')"    >
      <van-image src="/static/pp_img/vs20gravity.png"   class="img"  />
    </van-grid-item>
    
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs40infwild')"    >
      <van-image src="/static/pp_img/vs40infwild.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20candyblitz')"    >
      <van-image src="/static/pp_img/vs20candyblitz.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysstrlght')"    >
      <van-image src="/static/pp_img/vswaysstrlght.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20yisunshin')"    >
      <van-image src="/static/pp_img/vs20yisunshin.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaystut')"    >
      <van-image src="/static/pp_img/vswaystut.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10gdchalleng')"    >
      <van-image src="/static/pp_img/vs10gdchalleng.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysincwnd')"    >
      <van-image src="/static/pp_img/vswaysincwnd.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysftropics')"    >
      <van-image src="/static/pp_img/vswaysftropics.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20saiman')"    >
      <van-image src="/static/pp_img/vs20saiman.png"   class="img"  />
    </van-grid-item> 
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20earthquake')"    >
      <van-image src="/static/pp_img/vs20earthquake.png"   class="img"  />
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysbbhas')"    >
      <van-image src="/static/pp_img/vswaysbbhas.png"   class="img"  />
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20forge')"    >
      <van-image src="/static/pp_img/vs20forge.png"   class="img"  />
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20forge')"    >
      <van-image src="/static/pp_img/vs1024mahjwins.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20piggybank')"    >
      <van-image src="/static/pp_img/vs20piggybank.png"   class="img"  />
    </van-grid-item> 
  </van-grid>

  <van-grid :border="false" :column-num="3" v-if="currentPage==2">
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20lvlup')"    >
      <van-image src="/static/pp_img/vs20lvlup.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysrockblst')"    >
      <van-image src="/static/pp_img/vswaysrockblst.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10trail')"    >
      <van-image src="/static/pp_img/vs10trail.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs50dmdcascade')"    >
      <van-image src="/static/pp_img/vs50dmdcascade.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20lobcrab')"    >
      <van-image src="/static/pp_img/vs20lobcrab.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20supermania')"    >
      <van-image src="/static/pp_img/vs20supermania.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20wildparty')"    >
      <van-image src="/static/pp_img/vs20wildparty.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20starlightx')"    >
      <van-image src="/static/pp_img/vs20starlightx.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswayspowzeus')"    >
      <van-image src="/static/pp_img/vswayspowzeus.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20splmystery')"    >
      <van-image src="/static/pp_img/vs20splmystery.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs50jucier')"    >
      <van-image src="/static/pp_img/vs50jucier.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20cashmachine')"    >
      <van-image src="/static/pp_img/vs20cashmachine.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs243nudge4gold')"    >
      <van-image src="/static/pp_img/vs243nudge4gold.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs25jokrace')"    >
      <van-image src="/static/pp_img/vs25jokrace.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10bbextreme')"    >
      <van-image src="/static/pp_img/vs10bbextreme.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10bbextreme')"    >
      <van-image src="/static/pp_img/vs20hstgldngt.png"   class="img"  />
    </van-grid-item>

    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20procount')"    >
      <van-image src="/static/pp_img/vs20procount.png"   class="img"  />
    </van-grid-item>

    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20beefed')"    >
      <van-image src="/static/pp_img/vs20beefed.png"   class="img"  />
    </van-grid-item>
    

    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs9outlaw')"    >
      <van-image src="/static/pp_img/vs9outlaw.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20jewelparty')"    >
      <van-image src="/static/pp_img/vs20jewelparty.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20lampinf')"    >
      <van-image src="/static/pp_img/vs20lampinf.png"   class="img"  />
    </van-grid-item>

  </van-grid>
  <van-grid :border="false" :column-num="3" v-if="currentPage==3">
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysfrbugs')"    >
      <van-image src="/static/pp_img/vswaysfrbugs.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs4096robber')"    >
      <van-image src="/static/pp_img/vs4096robber.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20clustwild')"    >
      <van-image src="/static/pp_img/vs20clustwild.png"   class="img"  />
    </van-grid-item>
    
      
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs15godsofwar')"    >
      <van-image src="/static/pp_img/vs15godsofwar.png"   class="img"  />
    </van-grid-item>  
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs25spotz')"    >
      <van-image src="/static/pp_img/vs25spotz.png"   class="img"  />
    </van-grid-item>  
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20stickywild')"    >
      <van-image src="/static/pp_img/vs20stickywild.png"   class="img"  />
    </van-grid-item>  
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20excalibur')"    >
      <van-image src="/static/pp_img/vs20excalibur.png"   class="img"  />
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswayseternity')"    >
      <van-image src="/static/pp_img/vswayseternity.png"   class="img"  />
    </van-grid-item> 
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs25holiday')"    >
      <van-image src="/static/pp_img/vs25holiday.png"   class="img"  />
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20mvwild')"    >
      <van-image src="/static/pp_img/vs20mvwild.png"   class="img"  />
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10kingofdth')"    >
      <van-image src="/static/pp_img/vs10kingofdth.png"   class="img"  />
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysultrcoin')"    >
      <van-image src="/static/pp_img/vswaysultrcoin.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10jnmntzma')"    >
      <van-image src="/static/pp_img/vs10jnmntzma.png"   class="img"  />
    </van-grid-item> 
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10gizagods')"    >
      <van-image src="/static/pp_img/vs10gizagods.png"   class="img"  />
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysrsm')"    >
      <van-image src="/static/pp_img/vswaysrsm.png"   class="img"  />
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20hotzone')"    >
      <van-image src="/static/pp_img/vs20hotzone.png"   class="img"  />
    </van-grid-item> 
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysmonkey')"    >
      <van-image src="/static/pp_img/vswaysmonkey.png"   class="img"  />
    </van-grid-item> 
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs1024moonsh')"    >
      <van-image src="/static/pp_img/vs1024moonsh.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysredqueen')"    >
      <van-image src="/static/pp_img/vswaysredqueen.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10bbhas')"    >
      <van-image src="/static/pp_img/vs10bbhas.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20sknights')"    >
      <van-image src="/static/pp_img/vs20sknights.png"   class="img"  />
    </van-grid-item>
    
  </van-grid>
  <van-grid :border="false" :column-num="3" v-if="currentPage==4">
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20framazon')"    >
      <van-image src="/static/pp_img/vs20framazon.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20goldclust')"    >
      <van-image src="/static/pp_img/vs20goldclust.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20doghousemh')"    >
      <van-image src="/static/pp_img/vs20doghousemh.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysmorient')"    >
      <van-image src="/static/pp_img/vswaysmorient.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20pistols')"    >
      <van-image src="/static/pp_img/vs20pistols.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10powerlines')"    >
      <van-image src="/static/pp_img/vs10powerlines.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswayswwriches')"    >
      <van-image src="/static/pp_img/vswayswwriches.png"   class="img"  />
    </van-grid-item>  
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs25archer')"    >
      <van-image src="/static/pp_img/vs25archer.png"   class="img"  />
    </van-grid-item>  
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs12tropicana')"    >
      <van-image src="/static/pp_img/vs12tropicana.png"   class="img"  />
    </van-grid-item>  
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20mochimon')"    >
      <van-image src="/static/pp_img/vs20mochimon.png"   class="img"  />
    </van-grid-item>  
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20gatotfury')"    >
      <van-image src="/static/pp_img/vs20gatotfury.png"   class="img"  />
    </van-grid-item>  
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20superlanche')"    >
      <van-image src="/static/pp_img/vs20superlanche.png"   class="img"  />
    </van-grid-item>  
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10fisheye')"    >
      <van-image src="/static/pp_img/vs10fisheye.png"   class="img"  />
    </van-grid-item>  
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20ltng')"    >
      <van-image src="/static/pp_img/vs20ltng.png"   class="img"  />
    </van-grid-item>  
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs25spgldways')"    >
      <van-image src="/static/pp_img/vs25spgldways.png"   class="img"  />
    </van-grid-item>  

    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20mammoth')"    >
      <van-image src="/static/pp_img/vs20mammoth.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswayswwhex')"    >
      <van-image src="/static/pp_img/vswayswwhex.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysfuryodin')"    >
      <van-image src="/static/pp_img/vswaysfuryodin.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20aztecgates')"    >
      <van-image src="/static/pp_img/vs20clspwrndg.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs25rlbank')"    >
      <van-image src="/static/pp_img/vs25rlbank.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20dugems')"    >
      <van-image src="/static/pp_img/vs20dugems.png"   class="img"  />
    </van-grid-item>
    
  </van-grid>


  <van-grid :border="false" :column-num="3" v-if="currentPage==5">

    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswayspizza')"    >
      <van-image src="/static/pp_img/vswayspizza.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysrabbits')"    >
      <van-image src="/static/pp_img/vswaysrabbits.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20drgbless')"    >
      <van-image src="/static/pp_img/vs20drgbless.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysluckyfish')"    >
      <van-image src="/static/pp_img/vswaysluckyfish.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs10bbkir')"    >
      <van-image src="/static/pp_img/vs10bbkir.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20gatotgates')"    >
      <van-image src="/static/pp_img/vs20gatotgates.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20sparta')"    >
      <van-image src="/static/pp_img/vs20sparta.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20mtreasure')"    >
      <van-image src="/static/pp_img/vs20mtreasure.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20lcount')"    >
      <van-image src="/static/pp_img/vs20lcount.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vswaysconcoll')"    >
      <van-image src="/static/pp_img/vswaysconcoll.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20theights')"    >
      <van-image src="/static/pp_img/vs20theights.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20schristmas')"    >
      <van-image src="/static/pp_img/vs20schristmas.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs25kfruit')"    >
      <van-image src="/static/pp_img/vs25kfruit.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20porbs')"    >
      <van-image src="/static/pp_img/vs20porbs.png"   class="img"  />
    </van-grid-item>
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20kraken2')"    >
      <van-image src="/static/pp_img/vs20kraken2.png"   class="img"  />
    </van-grid-item>
    
    <van-grid-item  @click="$parent.openGamePage('PP', '2', 'vs20asgard')"    >
      <van-image src="/static/pp_img/vs20asgard.png"   class="img"  />
    </van-grid-item>
    




  </van-grid>

<div style="margin-top: 10px;margin-bottom:30px"> 
  <van-pagination v-model="currentPage" :total-items="21" :items-per-page="2"    />
</div>

</div>
</div>

  </div>
</template>
<script>
export default {
  name: 'gamePagePG',
  data() {
    return { 
      currentPage:1,
      active:0
    };
  },
  created() {
    let that = this;
    var query = that.$route.query;
    console.log(query);
 
  },
  methods: { 
    tabHandler(name, title) {
      console.log(name, title)
      if(title =='PG电子') {
        this.$parent.openGamePage('PG', '2', '')
      }
      if(title =='AG电子') {
        this.$parent.openGamePage('AG', '2', '')
      }
      if(title =='FC电子') {
        this.$parent.openGamePage('FC', '2', '')
      }
      if(title =='CQ9电子') {
        this.$parent.openGamePage('PP', '2', '')
      }
    },
    handleCurrentChange(val) { 
    //  this.currentPage = val
            },
    onClickLeft()
    {
      let that = this;
      that.$router.go(-1)
    }
 
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
    //that.$refs.iframe.contentWindow.location.replace(that.url);
  },
};
</script>

<style lang="scss" scoped>
 
// 游戏
.gameBoxs {
  background: #edf1ff;
  box-sizing: border-box;
  padding: 10px;
  color: #5d75a2; 
 
 
 
}
::v-deep  .van-grid-item__content {
  padding: 0px 8px;
}
::v-deep .van-grid-item__content{
  background: #edf1ff !important;
}
.text div{
  font-size: 14px;
  color: #5d4747;
  padding-top: 4px;

}
.img{
   width: 100%; height: 100%; display: block; 
   //border: 1px solid rgb(19, 19, 19);  
   
}
::v-deep  .van-image__img{
  border-radius: 6px;;
  height: 122px;
}
</style>

 